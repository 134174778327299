.skillContainer {
  border: 0.5px solid lightgrey;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;

  svg {
    margin-right: 5px;
  }
}
