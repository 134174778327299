.expierienceContainter {
  border: 0.5px solid lightgrey;
  justify-content: center;
  margin-bottom: 10px;
  padding: 20px 10px;
  border-radius: 10px;
  display: flex;
  text-align: start;

  h3 {
    margin: 0 0 5px 0;
  }
}
